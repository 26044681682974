.touch-menu-la {
    width: 280px!important;
    height: 100%;
    position: fixed;
    top: 0;
    left: -280px;
    background-color: #2e2d33;
    touch-action: none !important;
}

.touch-menu-la .tmla-handle {
    position: absolute;
    /* right: -20px; */
    top: 0;
    /* width: 20px; */
    height: 100%;
}

.touch-menu-la.opened {
    left: 0;
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
}

.touch-menu-la.closed {
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
}

.tmla-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.0;
    z-index: -1;
}
