
html{width: 100%; min-height: 100%; margin: 0; padding: 0;}
*{margin: 0; padding: 0; outline: 0 !important;}
table{border-spacing: 4px;}
fieldset, img{border: 0;}
sup{vertical-align: text-top;}
sub{vertical-align: text-bottom;}
input, textarea, select{font-family: inherit; font-size: inherit; font-weight: inherit;}
textarea{resize: none}
select{height: 50px; border: 1px solid #dfdede; border-radius: 3px; padding-left: 15px;  width: 100%; box-sizing: border-box; background: #fff}
select option{padding: 3px 20px; width: 100%; box-sizing: border-box;}
a{color: #4589c3; text-decoration: none}
label{cursor: pointer;}
ul li{list-style: none;}
input[type='button'], input[type='submit']{cursor: pointer;}
::-webkit-input-placeholder {color:#c2c2c2; opacity: 1}
::-moz-placeholder {color:#c2c2c2; opacity: 1}
:-moz-placeholder {color:#c2c2c2; opacity: 1}
:-ms-input-placeholder {color:#c2c2c2; opacity: 1}

.block{display: block;}
.inline{display: inline;}
.clear{clear: both; line-height: 0 !important; font-size: 0 !important; height: 0 !important; padding: 0 !important; border-bottom: none !important}
.clearfix:after{content:""; display: block; clear: both}
.hidden{display: none;}
.center{text-align: center}
.right{right: 20px !important}

.editable p{margin: 0 0 12px 0;}
.editable ol, .editable ul{margin: 0 0 12px 10px;}
.editable li{margin: 0 0 7px 0;}
.editable ol li{list-style: decimal; list-style-position: inside;}
.editable ul li{list-style: disc; list-style-position: inside;}
.editable table{margin: 0 0 12px 0;}
.editable table th{padding: 10px 10px; text-align: left;}
.editable table td{padding: 10px 10px; text-align: left;}
.editable h1, .editable h2, .editable h3, .editable h4, .editable h5, .editable h6{margin: 0 0 14px 0;}
.editable h1{font-size: 27px; line-height: 29px;}
.editable h2{font-size: 24px; line-height: 26px;}
.editable h3{font-size: 22px; line-height: 24px;}
.editable h4{font-size: 18px; line-height: 20px;}
.editable h5{font-size: 16px; line-height: 18px;}
.editable h6{font-size: 14px; line-height: 16px;}

@font-face{
font-family: "FutureAround";
src: url("../fonts/a_futuraround.ttf"); 
}

@font-face{
font-family: "FutureAround-Bold";
src: url("../fonts/a_futuraround-bold.ttf");
}