@media screen and (max-width: 1280px)
{
div.promo img{top: 120px}    
}

@media screen and (max-width: 1120px)
{
#header, #fixed-header{display: none}
#mobile-header{display: block}
div.promo .wrapper-max{padding: 0 20px 50px 60px}
div.promo img{top: 180px} 
}

@media screen and (max-width: 800px)
{
div.promo .wrapper-max{padding: 0 5% 30px 5%}
div.promo div.promo-text{float: none; width: 100%; margin: 0 auto}
div.promo img{position: static; width: 100%; margin-top: 20px}
div.promo h2{font-size: 50px}
div.promo p{font-size: 31px; margin-bottom: 30px}
div.feature:nth-child(2n) div.description{padding-right: 30px}
div.illustrating-screenshot:nth-child(n){float: none; margin: 0 auto 30px;}
div.illustrating-screenshot{width: 60%}
.form-block{padding-top: 50px}
.big{font-size: 50px}
div.to-top{bottom: 10px; right: 5px}
}

@media screen and (max-width: 700px)
{
div.feature div.img-wrapper{padding-right: 30px;}
div.feature div.img-wrapper img{width: 100%}
ol.problems{padding-right: 0}
div.illustrating-screenshot{width: 70%}
}

@media screen and (max-width: 600px)
{
#mobile-header div.logo{display: none}
div.logo.hidden{display: block}
h1{margin-top: 20px}
div.feature div.description {padding-top: 0;}
div.start{padding: 0}
div.illustrating-screenshot{width: 80%}
div.form-block div.img-wrapper, div.form-block div.form-wrapper{width: 100%; margin: 0;}
div.form-block{padding: 0}
div.price, ul.features-list{float: none; margin: 0 auto; width: 80%}
ul.features-list{list-style-position: inside}
form.support-form{padding: 30px 5%}
p.no-doubt{display: none}
p.no-doubt-mobile{display: block}
}

@media screen and (max-width: 480px)
{
ul.main-menu li{margin-left: 10px; font-size: 16px}
#mobile-header{padding: 0 10px}
h1, h2{font-size: 40px}
div.promo h2 span{font-size: 32px}
h3, div.title, div.send-question div.title {font-size: 25px}
h4{font-size: 24px}
.big{font-size: 45px}
div.promo img, div.promo div.promo-text{width: 100%}
div.promo h2{font-size: 40px}
div.promo p{font-size: 25px}
.phone-screenshot{width: 100%; float: none}
.button.check-up{margin: 30px auto 60px}
div.feature div.description, div.feature:nth-child(n) div.img-wrapper {float: none; padding: 0; margin: 0 auto; width: 80%}
div.feature:nth-child(n) div.img-wrapper{margin-bottom: 20px}
div.illustrating-screenshot{width: 100%}
div.form-block div.img-wrapper img{width: 100%}
div.send-question{padding-top: 40px}
div.price, ul.features-list{width: 100%}
div.form-block div.form-wrapper{padding: 30px 5%}
}

@media screen and (max-width: 375px)
{
div.send-question div.form-wrapper{width: 100%}
div.promo a.button{margin-right: 20px}
}

@media screen and (max-width: 360px)
{
body{font-size: 17px}
div.feature div.description, div.feature:nth-child(n) div.img-wrapper{width: 100%}
}

@media screen and (max-width: 345px)
{
div.promo a.underline-link{margin: 15px 0}
p.no-doubt-mobile{font-size: 21px}
ul.main-menu li{margin-left: 4px}
p.no-doubt{font-size: 22px}
div.form-block div.form-wrapper {padding: 50px 3%}
}