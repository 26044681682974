body{position: relative; font: 18px/1.3 "FutureAround"; color: #2f2d33; min-width: 320px; background: #f4f3f3}
body.with-background{background: #f4f3f3 url(../images/pattern.jpg)repeat;}
#container{min-height: 100%; margin-bottom: -168px; width: 100%; position: relative; }
#header{height: 70px; padding: 0 2.6%; background: #fff; position: relative; border-bottom: 1px solid #b8b7b7;
box-shadow: 0 0 20px rgba(120, 120, 120, 0.5)}
#content{min-height: 400px; position: relative; }
#pre-footer{height: 168px; clear: both; position: relative;}
#footer{height: 168px; padding-top: 55px; width: 100%; position: relative; box-sizing: border-box}
.wrapper-max{max-width: 1324px; margin: 0 auto; padding: 0 2%}
.wrapper-middle{max-width: 960px; margin: 0 auto; padding: 0 2%}
.wrapper-middle-index{max-width: 1064px; margin: 0 auto; padding: 0 2%}
.wrapper-min{max-width: 700px; margin: 0 auto; padding: 0 2%}

/* Common styles */
h1, h2{font: 56px "FutureAround-Bold"; margin-bottom: 25px}
h1{text-align: center; margin-top: 55px}
h3, div.title{font: 36px/1.4 "FutureAround-Bold"; margin: 50px 0 10px 0; text-align: center;}
h4{font-size: 30px; text-align: center}
div.title{margin-bottom: 10px}
p{margin-bottom: 15px}
.content.default{padding-top: 40px}
.content.default p{font-family: Arial; font-size: 16px;}
.content.default h1{margin: 0; padding: 25px 0}
a{color: #3283c9; display: inline-block; position: relative;}
a:hover{color: #2768a1;}
.link-like{color: #3283c9}
a.underline-link:before {display: block; position: absolute; content: ""; height: 1px; width: 0; background: #2768a1; transition: width 0.3s ease-in-out;
left: 0; bottom: 0;}
a.underline-link:hover:before {width: 100%;}
.underline{text-decoration: underline}
.underline:hover{text-decoration:none}
/* a.underline-link:before {display: block; position: absolute; content: ""; height: 2px; width: 0; background: #08589e; transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
left: 50%; bottom: 0;}
a.underline-link:after {display: block; position: absolute; content: ""; height: 2px; width: 0; background: #08589e; transition: width 0.3s ease-in-out;
left: 50%; bottom: 0;}
a.underline-link:hover:before {width: 50%; left: 0;}
a.underline-link:hover:after {width: 50%} */
/* a.underline-link:before {display: block; position: absolute; content: ""; height: 2px; width: 0; background: #08589e; transition: width 0.3s ease-in-out;
right: 0; bottom: 0;}
a.underline-link:hover:before {width: 100%;} */

span.color-highlight{color: #85af4b}
.red{color: #d3533a!important;}
.green{color: #29a98a}
.blue{color: #3283c9}
cite{font-family: "FutureAround-Bold"; margin-bottom: 20px; display: block; font-style: normal}
blockquote p{font-size: 24px; padding-right: 20px; margin-bottom: 50px;}
ol{padding: 0 0 15px 20px}
ol li{padding: 0 0 15px 10px}
ol.center li{list-style-position: inside}
mark{background-color: #eedca0}
div.to-top{position: fixed; right: 5%; bottom: 50px; width: 54px; height: 54px; display: none; cursor: pointer;
background: url("../images/to-top.svg")no-repeat center center; background-size: contain; opacity: 0.5}
::selection {background: #85af4b; color: #fff}
::-moz-selection {background: #85af4b; color: #fff}
img::selection{background: none}
img::-moz-selection{background: none}
.not-visible{opacity: 0;}
.visible{opacity: 1;}
.center-text{text-align: center}
h3 + p.center-text{margin-bottom: 20px}
.white-background{background: #fff; padding-bottom: 10px}
.with-extra-info{margin-bottom: 10px}
.big{font-size: 80px; line-height: 1}

/*Buttons*/
.button{color: #fff !important; background: url("../images/button.svg") no-repeat center center; background-size: cover; width: 153px; height: 55px;
text-align: center; display: inline-block; line-height: 55px; font-family: "FutureAround-Bold"; border: none}
.button:hover{color: #fff !important; background: url("../images/button-hover.svg") no-repeat center center; background-size: cover;}
.button.login{width: 115px; height: 42px; line-height: 42px}
#login-form .button.login{background: url("../images/button-green.svg") no-repeat center center; background-size: cover;
margin: 0 auto; width: 175px; height: 55px; line-height: 55px}
#login-form .button.login:hover{background: url("../images/button-green-hover.svg") no-repeat center center; background-size: cover;}
.button.registration{background: url("../images/button-green-long.svg") no-repeat center center; background-size: cover; margin: 0 auto 30px;
width: 320px; height: 72px; line-height: 72px; display: block; font-size: 24px;}
.button.registration:hover{background: url("../images/button-green-long-hover.svg") no-repeat center center; background-size: cover;}
.button.check-up{background: url("../images/button-green-long.svg") no-repeat center center; background-size: contain; width: 310px; height: 74px; line-height: 74px;
margin: 55px auto; display: block; font-size: 24px;}
.button.check-up:hover{background: url("../images/button-green-long-hover.svg") no-repeat center center; background-size: contain;}
.button.sign-up {width: 300px; background: url("../images/button-long.svg") no-repeat center center; background-size: cover}
.button.sign-up:hover{background: url("../images/button-long-hover.svg") no-repeat center center; background-size: cover}
.button.sign-up.center{margin: 45px auto 60px; display: block}
.policy-note{font-size: 15px; margin-bottom: 15px;}
div.form-block form .policy-note a{display: inline; top: 0; right: 0; position: relative; text-decoration: underline;}

/*Forms*/
div.form-errors, div.form-success {margin: 5px 0 15px; padding: 10px; width: 100%; font-size: 15px; box-sizing: border-box;}
div.form-errors {background: #f9f3d7; color: #d63c1d;}
div.form-success{background: #c1f7c0; color: #217c20;}
div.form-errors.react{transform: scale(0.3); opacity: 0.5;}
div.form-errors.animated{transform: scale(1); opacity: 1; transition: opacity 300ms, transform 300ms}
div.form-errors p, div.form-success p{margin-bottom: 0; text-align: center}
::-webkit-input-placeholder {color:#2f2d33; opacity: 1}
::-moz-placeholder {color:#2f2d33; opacity: 1}
:-moz-placeholder {color:#2f2d33; opacity: 1}
:-ms-input-placeholder {color:#2f2d33; opacity: 1}
div.send-question div.form-wrapper{width: 360px; margin: 0 auto;}
form input[type="text"], form textarea{width: 100%; padding: 10px 15px; font-size: 15px; margin-bottom: 12px;
box-sizing: border-box; border-radius: 3px; border: none}
form textarea{height: 125px}
form input[type="file"]{margin-bottom: 12px}
div.form-buttons{text-align: center}
div.field-name{font-size: 15px; padding-bottom: 10px}
div.field-name span{color: #85af4b}
form.support-form{padding: 30px 24%; box-shadow: 0 0  10px rgba(120, 120, 120, 0.5); border-radius: 5px; background-color: #fff}
form.support-form input[type="text"], form.support-form textarea{border: 1px solid #cccaca;}
form.support-form .button{margin-top: 30px}
.file-upload {position: relative; overflow: hidden; height: 42px;  border-radius: 3px;
box-sizing: border-box; font-size: 15px; float: left;  width: 100%;}
.file-upload input[type="file"]{}
.file-upload label {display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer;}
.file-upload span {line-height: 42px;}
.filename {background: #fff; border: 0 !important;  float: left; padding: 10px 0 !important;
min-height: 42px}


/* Header */
div.logo{float: left; margin-top: 15px; background: url("../images/logo.svg")no-repeat; width: 186px; height: 44px}
div.logo a{display: block; width: 100%; height: 100%}
ul.main-menu {float: right; line-height: 70px; font-family: "FutureAround-Bold"}
ul.main-menu li{display: inline-block; margin-left: 30px}
ul.main-menu li a{height: 100%; color: #2f2d33}
ul.main-menu li a:hover, ul.main-menu li a.active{color: #85af4b}
#fixed-header{position: fixed; top:0; left:0; width: 100%; background: #2e2d33; box-sizing: border-box; z-index: 10000000; padding: 0 2.6%;
display: none}
#fixed-header a{color: #fff}
#fixed-header a:hover{color: #85af4b}
#fixed-header div.logo, #mobile-menu div.logo{background: url("../images/logo-mobile.svg")no-repeat;}
#mobile-header{display: none; background: #2e2d33; padding: 0 18px}
#mobile-header a{color: #fff}
#mobile-header div.logo{background: url("../images/logo-mobile.svg")no-repeat; background-size: contain}
#mobile-header div#menu-button{width: 39px; height: 23px; background: url("../images/menu.svg") no-repeat center center; background-size: contain;
float: left; margin: 20px 20px 0 0; cursor: pointer}
#mobile-menu{padding: 42px 20px; box-sizing: border-box; z-index: 100100 !important}
#mobile-menu .logo{float: none; margin-bottom: 20px}
#mobile-menu ul li a{display: block; color: #fff; padding: 10px 0; width: 100%; font-family: "FutureAround-Bold"}
#mobile-menu ul li a:hover{color: #85af4b}
#mobile-menu #menu-close{width: 25px; height: 25px; position: absolute; top: 20px; right: 20px; background: #2e2d33 url("../images/close.svg")no-repeat center center;
background-size: contain; cursor: pointer}
div.logo.hidden{margin: 30px auto 15px; float: none}

/*Promo*/
div.promo {min-height: 600px; border-bottom: 1px solid #b8b7b7; background: url(../images/promo-background.jpg)no-repeat; background-size: cover;
position: relative}
div.promo .wrapper-max{max-width: 1600px; padding: 50px 20px 50px 60px;}
div.promo img{display: block; width: 47%; position: absolute; right: 8%; top: 50px;}
div.promo div.promo-text{float: left; width: 50%}
div.promo h2{padding-top: 55px; font-size: 64px; line-height: 1.2; color: #f0e248; position: relative; z-index: 10; margin-bottom: 35px}
div.promo p{color: #fff; font: 40px "FutureAround-Bold"; margin-bottom: 50px; position: relative; z-index: 10;}
div.promo a{position: relative; z-index: 10;}
div.promo a.button{margin-right: 45px; background: url(../images/button-blue-light.svg)no-repeat center center; background-size: cover;}
div.promo a.button:hover{background: url(../images/button-blue-light-hover.svg)no-repeat center center; background-size: cover;}
div.promo a.underline-link{color: #32c7c9; text-decoration: underline; background: url(../images/play-button.png)no-repeat center right;
padding-right: 25px; margin-bottom: 50px}
div.promo a.underline-link + p{font: 18px "FutureAround"}

/* Content */
.browser-screenshot{display: block; width: 100%}
.phone-screenshot{display: block; width: 50%; float: left}
div.description-title{font: 24px "FutureAround-Bold"; color: #85af4b; margin-bottom: 10px}
div.send-question{background: #201e23; padding: 65px 2% 55px ;}
div.send-question div.title{color: #85af4b; font: 36px/1.6 "FutureAround-Bold"; margin-bottom: 15px; text-align: center}
div.send-question p.annotation{color: #f4f3f1; margin-bottom: 40px; text-align: center}
div.video-wrapper{max-width: 960px; margin: 40px auto}
video {width: 100%; height: auto; max-height: 100%; border: 2px solid #484848}
div.start{padding: 0 13.5%; margin-bottom: 40px}

div.screenshots{margin-bottom: 60px}
div.illustrating-screenshot{float: left; width: 48%; position: relative}
div.illustrating-screenshot p{text-align: center}
div.illustrating-screenshot img.screenshot{display: block; width: 100%}
div.illustrating-screenshot:first-child{margin-right: 4%}
div.illustrating-screenshot div.pointer{position: absolute}
div.illustrating-screenshot div.pointer.choose-intervals{top: 130px; left: -140px}
div.illustrating-screenshot div.pointer.mark-hours{top: 466px; left: -140px}
div.illustrating-screenshot div.pointer.mark-days{top: 380px; right: -127px}
div.illustrating-screenshot div.pointer.reserve-time{top: 295px; left: -188px}
div.illustrating-screenshot div.pointer.add-time{top: 545px; left: -130px}
div.illustrating-screenshot div.pointer.confirm-cancel{top: 265px; right: -178px}

div.price{width: 50%; background-color: #fff; box-shadow: 0 0  10px rgba(120, 120, 120, 0.5); border-radius: 5px; padding: 47px 11%;
box-sizing: border-box; float: left}
div.price p{text-align: center}
div.price p:first-child{font: 30px "FutureAround-Bold"}
div.price p.icons{background: url("../images/payment-icons.svg")no-repeat center center; background-size: contain; height: 33px; width: 100%; margin-top: 35px}
ul.features-list{float: right; width: 41.7%; padding-top: 25px}
ul.features-list li{padding: 0 0 0 25px; margin-bottom: 15px; list-style: none; background: url(../images/check.svg)left top no-repeat; background-size: 19px 20px}
div.questions-answers p:nth-child(odd){font-family: "FutureAround-Bold"}
#unsubscribe-now-link{text-align: center;}
#unsubscribe-now-link a{text-decoration: underline;}

/*Registration/login*/
.form-block{padding-top: 40px}
form p, form span{font-size: 15px}
div.form-block div.img-wrapper{width: 50%; float: left}
div.form-block div.img-wrapper img{display: block; margin: 0 auto}
div.form-block div.form-wrapper{width: 483px; margin: 0 auto 0; box-shadow: 0px 0px 9px 0px rgba(77, 76, 76, 0.26); border-radius: 5px;
box-sizing: border-box; padding: 50px 60px; background-color: rgba(255, 255, 255, 0.8)}
div.form-block div.form-wrapper .title{margin: 0 0 10px 0}
div.form-block div.form-wrapper p.annotation{font-family: "FutureAround-Bold"; text-align: center}
div.form-block form input[type="text"],
div.form-block form input[type="password"]{width: 100%; font-size: 15px; padding: 10px;
margin-bottom: 15px; box-sizing: border-box; border: 1px solid #cccaca;}
div.form-block form input[type="password"]{padding-right: 130px}
div.form-block form input[name="captcha"]{float: left; width: calc(100% - 120px - 15px); margin-right: 15px; margin-bottom: 30px}
div.form-block form input img{float: left}
div.form-block form div.field-input{position: relative}
div.form-block form div.field-input span{position: absolute; top: 10px; right: 10px}
div.form-block form a{display: block; position: absolute; top: 10px; right: 10px; font-size: 15px;}
div.form-block div.logo{float: none; margin: 0 auto 30px}
hr{height: 2px; background: #d1d0d0; border: none; margin: 30px 0}
div.form-block div.account-create{text-align: center}
div.form-block div.account-create span{display: block; font-size: 15px; margin-top: 10px}
div.form-block.login-block div.img-wrapper img{margin-top: 70px}
div.remember-me{margin-bottom: 12px; font-size: 15px}
div.remember-me label{margin-left: 5px;}

/*Footer*/
#footer{text-align: center; font-size: 15px}
#footer a{display: inline-block; margin: 0 10px}
.cookie-message{position: fixed; width: 100%; bottom: 0; left: 0; background: #3283c9; padding: 15px 0; text-align: center;
    font: 14px/1.2 Arial; color: #fff}
.cookie-message .close{width: 18px; height: 18px; position: absolute; top: 50%; margin-top: -9px;  right: 15px;
    background: url("../images/close.svg")no-repeat center center; background-size: contain; cursor: pointer}
.cookie-message .wrapper-middle{position: relative; padding: 0 40px 0 15px}
.cookie-message a{color: #fff; border-bottom: 1px solid #fff}
.cookie-message a:hover{border-bottom: none}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }